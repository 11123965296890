export const CARS = [
  {
    carType: 'Sedan',
    'oneway': 14,
    'roundtrip': 13,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
    passengers: 4,
    image: 'https://images.ctfassets.net/509kpi6dw56l/6QdZLGkGnMv0F8fDwFMGlE/1b85e10c7d90fdac89a4794938a43780/prime-play-1.png?h=250',
    activeImage: 'https://images.ctfassets.net/509kpi6dw56l/1jwgHXg6XJ6uog96Dh2BVd/317481d3bd8a7f97ee19d8db7b5e9b95/prime-play-1-1.png?h=250'

  },
  {
    carType: 'Etios',
    'oneway': 14,
    'roundtrip': 13,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
    passengers: 4,
    image: 'https://images.ctfassets.net/509kpi6dw56l/7s8MKkYx4bSfPYlrqUEAW5/c23e3f9a5f9ebbbca681d63f0e8b8130/lux-1.png?h=250',
    activeImage: 'https://images.ctfassets.net/509kpi6dw56l/2xxEOELbZtfsJPYIlhbuhK/0b7a9d17525dea250d2f42cde3352fec/lux-1-1.png?h=250'
  },
  {
    carType: 'Suv',
    'oneway': 19,
    'roundtrip': 18,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    passengers: 6,
    image: 'https://images.ctfassets.net/509kpi6dw56l/72yoz2W0gPFPq50SfgPPqU/2c521cd2260cff9246ea2955bc37b707/prime-suv-1.png?h=250',
    activeImage: 'https://images.ctfassets.net/509kpi6dw56l/4BttjSr5H4GMEi6gDzq8hP/013408e51a5ac899cf05f07e708d254e/prime-suv-1-1.png?h=250'

  },
  {
    carType: 'Innova',
    'oneway': 20,
    'roundtrip': 19,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    passengers: 6,
    image: 'https://images.ctfassets.net/509kpi6dw56l/72yoz2W0gPFPq50SfgPPqU/2c521cd2260cff9246ea2955bc37b707/prime-suv-1.png?h=250',
    activeImage: 'https://images.ctfassets.net/509kpi6dw56l/4BttjSr5H4GMEi6gDzq8hP/013408e51a5ac899cf05f07e708d254e/prime-suv-1-1.png?h=250'
  },
  {
    carType: 'Tempo',
    'oneway': 24,
    'roundtrip': 23,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    passengers: 6,
    image: 'https://images.ctfassets.net/509kpi6dw56l/1vR0i31WxazEtFukHIaJb1/507f7992131283b34dfec7c99b94da6e/tempo.png?h=250',
    activeImage: 'https://images.ctfassets.net/509kpi6dw56l/6nJrRzhLm5SvbR6UZyletj/c42b30a8d8cdebb3b9450b59317faff1/lux-1__1_.png?h=250'
  },

  {
    carType: 'Bus',
    'oneway': 35,
    'roundtrip': 45,
    'onewayDriverFee': 500,
    'roundTripDriverFee': 500,
    availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    passengers: 6,
    image: 'https://images.ctfassets.net/qawrl1k0q40m/3Gbxwe3aTsNaMshkqVmEyM/ccf20bd790bae1eb5133ab9522099ef6/bus-306857_640.png?h=250',
    activeImage: 'https://images.ctfassets.net/qawrl1k0q40m/16Ap4B5UEf2Tm0vKme0x7A/ba906a10b655fac4ed79aa6411a14a87/bus-306857_640__1_.png?h=250'
  },
];