<section class="wo-main-section">
    <div class="container">
        <div class="row">
            <div id="wo-twocolumns" class="wo-twocolumns">
                <div class="col-lg-8">
                    <div class="wo-vsinglehead">
                        <div class="wo-vsinglehead__title">
                            <h1><span>{{origin}}</span> to <span>{{destination}} Outstation Taxi</span></h1>
                        </div>
                    </div>
                    <div id="wo-vsingleslider" class="wo-vsingleslider owl-carousel owl-loaded owl-drag">
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                            <ng-container *ngIf="showDirection">
                                <agm-direction [origin]="origin" [destination]="destination"
                                    (onResponse)="onResponses($event)">
                                </agm-direction>
                            </ng-container>
                        </agm-map>

                    </div>
                    <ul *ngIf="estimations.length" class="wo-vslider-features">
                        <li>
                            <img src="assets/car.png" alt="img description">
                            <h4>{{estimations[0].carType}}</h4>
                            <span>Car Type</span>
                        </li>
                        <li>
                            <img src="assets/distance.png" alt="img description">
                            <h4>{{estimations[0].oneWayDistance}} KM</h4>
                            <span>Total Distance</span>
                        </li>
                        <li>
                            <img src="assets/time.png" alt="img description">
                            <h4>{{estimations[0].totalDuration}}</h4>
                            <span>Total Duration</span>
                        </li>
                        <li>
                            <img src="assets/money.png" alt="img description">
                            <h4>{{estimations[0].oneway}} ₹</h4>
                            <span>Rate Per KM</span>
                        </li>
                        <li>
                            <img src="assets/van.png" alt="img description">
                            <h4>{{estimations[0].oneWaytripEstimation | currency: "INR"}}</h4>
                            <span>Trip Cost</span>
                        </li>
                        <div class="wo-priceinfo__contact">
                            <a class="wo-contectnum"><i class="ti-mobile"></i>Click To
                                Call<span>{{settings.phoneNo}}</span></a>
                            <a class="wo-contectnum wo-contectmsg"><i class="fab fa-whatsapp"></i>Chat with Us<span>
                                    {{settings.whatsapp}} </span></a>
                        </div>
                    </ul>

                    <div class="wo-vsingledetails">
                        <div class="wo-vsingledetails__title">
                            <h3>Book {{origin}} to {{destination}} Drop Taxi</h3>
                        </div>
                        <div class="wo-vsingledetails__content">
                            <div class="wo-description">
                                <p>If you're planning a trip from {{origin}} to {{destination}}, you'll want to make sure that
                                    you have reliable and comfortable transportation to get you there. That's where
                                    Hello Taxicomes in! Our drop taxi service offers safe and affordable travel
                                    options that will get you from {{origin}} to {{destination}} in style.

                                </p>
                                <p>
                                    One of the biggest advantages of using Hello Taxiis our experienced drivers.
                                    Our drivers are well-trained and knowledgeable about the roads and traffic
                                    conditions in South India, ensuring that you'll arrive at your destination safely
                                    and on time. Plus, our fleet of vehicles is regularly serviced and maintained to
                                    ensure that you have a smooth and comfortable ride.


                                </p>
                                <p>
                                    Another advantage of using Hello Taxiis our flexible scheduling options. We
                                    understand that travel plans can change unexpectedly, which is why we offer one-way
                                    trips and round-trip journeys, giving you the freedom to choose the option that
                                    works best for your needs.


                                </p>
                                <p>
                                    When you book with Hello Taxi, you can rest assured that you're getting a fair
                                    and transparent pricing structure. We believe in upfront and honest pricing, with no
                                    hidden fees or surprises. You'll know exactly what you're paying for and how much it
                                    will cost before you even step into one of our vehicles.


                                </p>
                                <p>
                                    Traveling with Hello Taxialso means that you'll have access to our customer
                                    service team 24/7. If you have any questions or concerns before, during, or after
                                    your trip, our team is available to assist you in any way possible.


                                </p>
                                <p>

                                    In summary, if you're looking for a reliable and comfortable way to travel from
                                    {{origin}} to {{destination}}, Hello Taxiis the way to go. With experienced drivers,
                                    flexible scheduling options, transparent pricing, and excellent customer service,
                                    we're confident that we can make your journey a memorable and stress-free one.





                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>